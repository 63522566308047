<template>
  <div>
    <card-container>
      <div class="mr-6" slot="left">
        <div class="header-text">
          <h1 class="textOrange--text display-1 pb-8">
            Time to Build your
            <br />Introduction BeeZCard
          </h1>
        </div>
        <div class="text-container body-1 font-weight-light">
          An <span class="textOrange--text">Introduction BeeZCard</span> is a digital Card that could tell
          a lot about a person, so the next time you're in a Big Company
          Event you can just easily start a conversation with anyone by
          just looking at their <span class="textOrange--text">BeeZCard</span>. So Please take your time in
          building your Introduction <span class="textOrange--text">BeeZCard</span>.
        </div>
      </div>
      <div slot="right">
        <div class="ma-0 pa-0">
          <introduction-preview
            :jobTitle="card.jobTitle"
            :aboutTitle="card.aboutTitle"
            :section0="card.sections[0].title"
            :section1="card.sections[1].title"
          />
        </div>
      </div>
    </card-container>
    <bz-footer
      text="Click on Next Button to Edit Introduction BeeZCard"
      buttonTitle="Next"
      to="introduction-build"
    ></bz-footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import CardContainer from "../../../components/CardContainer";
import BzButton from "@/shared/components/BzSubmitButton";
import IntroductionPreview from "@/beez-admin/modules/on-boarding/pages/IntroductionBeeZCard/IntroductionBeeZCardPreview";
export default {
  name: "IntroductionCard",
  data() {
    return {
      card: {
        id: null,
        jobTitle: "",
        aboutTitle: "",
        sections: ["", ""]
      },
      accountId: null,
      topMargin: "mt-12",
      topPadding: "pt-12"
    };
  },
  methods: {
    ...mapActions("introduction", {
      getIntroductionCard: "getIntroductionCard"
    })
  },
  async created() {
    this.accountId = this.currentUser.roles[0].accountId;
    const card = await this.getIntroductionCard({ accountId: this.accountId });
    this.card.id = card[0]._id;
    this.card.jobTitle = card[0].jobTitle;
    this.card.aboutTitle = card[0].aboutTitle;
    let sections = [];
    let sectionObj = {};
    for (var index in card[0].sections) {
      sectionObj["id"] = card[0].sections[index]._id;
      sectionObj["title"] = card[0].sections[index].title;
      sections.push(sectionObj);
      sectionObj = {};
    }
    this.card.sections = sections;
  },
  computed: {
    ...mapFields("auth", ["currentUser"])
  },
  components: {
    CardContainer,
    BzButton,
    IntroductionPreview
  }
};
</script>
<style scoped>
.text-container {
  max-width: 593px;
  word-break: keep-all;
}
</style>