<template>
  <v-container class="mt-0 mx-0 mb-12 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col class="px-0 mx-0 mb-0 pb-0 mt-12 pt-12" sm="12" lg="5" md="5">
        <slot name="left"></slot>
      </v-col>
      <v-col align="center" sm="12" lg="7" md="7">
        <slot name="right"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "CardContainer"
};
</script>